import React,{ Suspense, useEffect,useState } from 'react';
import { Calendar } from 'react-bootstrap-icons';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import Loading from './Loading';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import {toast} from 'react-toastify';
import { CourseTypes } from 'src/crm/utils/list';
import config from 'src/globals'
import Login from '../crm/Login';
import ForgotPassword from '../crm/ForgotPassword';
import PasswordReset from '../crm/PasswordReset';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

const Home = React.lazy(() => import('./Home'));
const Contact = React.lazy(() => import('./Contact'));
const About = React.lazy(() => import('./About'));
const FullTimeCourses = React.lazy(() => import('./FullTimeCourses'));
const FullTimeNonVocationalCourses = React.lazy(() => import('./FullTimeNonVocationalCourses'));
const PartTimeCourses = React.lazy(() => import('./PartTimeCourses'));
const Professors = React.lazy(() => import('./Professors'));
const BetterJobsOntario = React.lazy(() => import('./BetterJobsOntario'));
const InternationalStudents = React.lazy(() => import('./InternationalStudents'));
const FinancialAid = React.lazy(() => import('./FinancialAid'));
const CareerStudent = React.lazy(() => import('./CareerStudent'));
const COJG = React.lazy(() => import('./COJG'));
const Policies = React.lazy(() => import('./Policies'));
const CoursePage = React.lazy(() => import('./CoursePage'));
const CoursePageFullTime = React.lazy(() => import('./CoursePageFullTime'));
const CalendarPage = React.lazy(() => import('./CalendarPage'));
const CourseSearch = React.lazy(() => import('./CourseSearch'));
const SearchQuery = React.lazy(() => import('./SearchQuery'));
const CovidPage = React.lazy(() => import('./CovidPage'));
const KPI = React.lazy(() => import('./Kpi'));
const ADCourses = React.lazy(() => import('./ADCourses'));
const WebDev = React.lazy(() => import('./WebDevCourse')); 
const Thankyou = React.lazy(() => import('./Thankyou'));

const DefaultLayout = React.lazy(() => import('../crm/layout/DefaultLayout'))

const NotFound = React.lazy(() => import('./404'));

const Router = (props) => {

    const baseURL = props.baseURL;

    const [show, setShow] = useState(false);
    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [redirect_url, setRedirectUrl] = useState('');
    // const [middle_name, setMiddleName] = useState('');
    // const [city, setCity] = useState('');
    // const [province, setProvince] = useState('');
    const [source, setSource] = useState(1);
    const [course, setCourse] = useState("");
    const [courseType, setCourseType] = useState("");
    const [courses, setCourses] = useState([]);
    const [location , setLocation] = useState(1);
    const [financialSelect, setFinancialSelect] = useState(false);
    const token = localStorage.getItem('token');
    const [courseSelectState, setCourseSelectState] = useState(false);
    const [courseTypeSelectState, setCourseTypeSelectState] = useState(false);
      const getCourses = async () => {
        const { data } = await axios.get(`${config.globals.backend_url_api}/courses`);
        setCourses(data);
    };
    useEffect(() => {
        // check if exist gclid in session storage
        
        if(sessionStorage.getItem('gclid')){
            setSource(3);
        }else if(sessionStorage.getItem('fbclid')){
            setSource(4);
        }else{
            setSource(1);
        }
        // if(sessionStorage.getItem('fbclid')){
        //     setSource(4);
        // }

    }, []);

    const navigate = useNavigate();
    const handleClose = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setPhone('');
        setAddress('');
        setCourse('');
        setCourseType('');
        setRedirectUrl('');
        setLocation(true);
        setFinancialSelect(false);
        setCourseSelectState(false);
        setCourseTypeSelectState(false);
        setShow(false);
    };
  
    const handleShow = (e, course="", course_type="", redirect_url="") => {
        setRedirectUrl(redirect_url);

        if(course !=="" || course_type !=""){
            setCourse(course);
            setCourseType(course_type);
            getCourses();

        }else{
            getCourses();

        }
      
        setShow(true);

        
        if(course !==""){
            setCourseSelectState(true);
        }

        if(course_type !== ""){
            setCourseTypeSelectState(true);
        }
    }
  
    const changeCourseType = (e) => {
      setCourseType(e.target.value);
      axios.get(`${config.globals.backend_url_api}/courses/search?course_type=${e.target.value}`, {
          // headers: {
          //     'Authorization': `${token}`
          // }
      })
      .then(res => {
          setCourses(res.data);
      })
      .catch(err => {
          console.log(err);
      })
  }


const handleSubmit = async e => {
    // if phone is empty or less than 10 digits
    if(phone === "" || phone.length < 7){
        toast.error("Please enter a valid phone number");
        return;
    }
    const btn = document.getElementById("btn-submit");
    btn.innerHTML = `<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Sending...`;
    btn.disabled = true;
    e.preventDefault();
    await AddLead({
        first_name,
        last_name,
        email,
        lead_type:1,
        phone,
        address,
        source : source,
        course_id: course,
        course_type: courseType,
        location,
        financial_aid: financialSelect,
    });
}

const AddLead = async (lead) => {
    await axios.post(`${baseURL}/leads`, lead)
    .then(res => {
        if(res.data.status === "success"){
            if(redirect_url !== ""){
                handleClose();
                return navigate(redirect_url);
            }
            // toast.success("Request Sent Successfully");
            setFirstName('');
            setLastName('');
            setEmail('');
            setPhone('');
            setAddress('');
            setSource(1);
            setCourse('');
            setCourseType('');
            setLocation(true);
            setFinancialSelect(false);
            navigate('/thank-you');
            handleClose();
        }else{
            toast.error(res.data.message);
        }
    }).catch((error) => {
        if (error.response) {
            toast.error(error.response.data.message);
            // console.log(error.response.data.message);
        }
    })
    .finally(() => {
        const btn = document.getElementById("btn-submit");
        btn.innerHTML = `Submit`;
        btn.disabled = false;
    })
}


  // const LeadModal = () =>{
  //   return (
      
  //   )
  // }

  return (
        <>
        <Suspense fallback={<Loading />}>

            <Routes>
                <Route path="/" element={<Home baseURL={baseURL} handleShow={handleShow} />} />
                <Route path="/contact" element={<Contact handleShow={handleShow} baseURL={baseURL} />} />
                <Route path="/about-us" element={<About handleShow={handleShow} />} />
                <Route path='/professors' element={<Professors handleShow={handleShow} />} />
                <Route path='/better-jobs-ontario' element={<BetterJobsOntario handleShow={handleShow} />} />
                <Route path='/second-career' element={ <Navigate replace to="/better-jobs-ontario" /> }/>
                <Route path='/international-students' element={<InternationalStudents handleShow={handleShow} />} />
                <Route path='/financial-aid' element={< FinancialAid handleShow={handleShow} />} />
                <Route path='/career-student-services' element={< CareerStudent handleShow={handleShow} />} />
                <Route path='/cojg' element={< COJG handleShow={handleShow} />} />
                <Route path='/policies' element={< Policies handleShow={handleShow} />} />
                <Route path='/calendar' element={< CalendarPage handleShow={handleShow} />} />
                <Route path='/course-search/:keyword' element={< CourseSearch handleShow={handleShow} />} />
                <Route path='/search/:query' element={<SearchQuery handleShow={handleShow} />} />
                <Route path="/full-time-courses" element={<FullTimeCourses handleShow={handleShow} />} />
                <Route path="/full-time-courses-non-vocational" element={<FullTimeNonVocationalCourses handleShow={handleShow} />} />
                <Route path='/part-time-courses/cisco/ccnp/ccnp-routing-and-switching/' element={ <Navigate replace to="/part-time-courses/cisco/ccnp/ccnp-enterprise" /> }/>
                <Route path='/full-time/network-specialist-diploma-52-weeks/' element={ <Navigate replace to="/full-time/network-specialist-diploma" /> }/>
                <Route path="/full-time-courses/*" element={<FullTimeCourses handleShow={handleShow} />} />
                <Route path="/full-time-courses-non-vocational/*" element={<FullTimeNonVocationalCourses handleShow={handleShow} />} />

                <Route path='/part-time-courses' element={<PartTimeCourses handleShow={handleShow} /> } />
                <Route path="/part-time-courses/*" element={<PartTimeCourses handleShow={handleShow} /> } />
                <Route path='/course/:course' element={< CoursePage handleShow={handleShow} /> } />
                <Route path='/full-time/:course' element={< CoursePageFullTime handleShow={handleShow} /> } />
                <Route path='/covid-19-vaccination-policy' element={< CovidPage handleShow={handleShow} /> } />
                <Route path='/mandatory-covid-19-vaccination-policy/' element={ <Navigate replace to="/covid-19-vaccination-policy" /> }/>
                <Route path="/kpi" element={<KPI handleShow={handleShow} />}  />
                <Route path="/programs" element={<ADCourses handleShow={handleShow} />}  />
                <Route path="/thank-you" element={<Thankyou handleShow={handleShow} />}  />
                <Route path='/web-development-course' element={<WebDev handleShow={handleShow} />} />
                <Route path='/second-career' element={ <Navigate replace to="/better-jobs-ontario" /> }/>

                <Route path="crm/login" element={<Login />} />
                <Route path="crm/forgot-password" element={<ForgotPassword />} />
                <Route path="crm/password-reset/:userId/:token" element={<PasswordReset />} />
                <Route path="crm/*" element={<DefaultLayout />} />
                <Route path='/crm/calendar.php' element={ <Navigate replace to="/calendar" /> }/>


                <Route path="/*" element={<NotFound />} />

            </Routes>
          
        </Suspense>

        <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title className='text-np-success fw-bold fs-np-24'>Student Inquiry Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form className='px-md-5' onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                    <Form.Control
                        type="text"
                        placeholder="First Name *"
                        autoFocus
                        className='rounded-0 form-control-sm'
                        onChange={(e) => setFirstName(e.target.value)} value={first_name}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Control
                        type="text"
                        placeholder="Last Name *"
                        className='rounded-0 form-control-sm'
                        onChange={(e) => setLastName(e.target.value)} value={last_name}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                <PhoneInput
                    placeholder="Phone *"
                    countryCallingCodeEditable={false}
                    international
                    defaultCountry='CA'
                    value={phone}
                    onChange={setPhone}
                />
                    {/* <Form.Control
                        type="text"
                        placeholder="Phone *"
                        className='rounded-0 form-control-sm'
                        onChange={(e) => setPhone(e.target.value)} value={phone}
                    /> */}
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Control
                        type="email"
                        placeholder="Email *"
                        className='rounded-0 form-control-sm'
                        onChange={(e) => setEmail(e.target.value)} value={email}
                
                    />
                </Form.Group>
                {/* <Form.Group className="mb-3">
                    <Form.Control
                        as="textarea"
                        placeholder="Address *"
                        className='rounded-0 form-control-sm'
                        onChange={(e) => setAddress(e.target.value)} value={address}
                        height={{height: '100px'}}
                    />
                </Form.Group> */}
                {/* radio input for location */}
                <Form.Group className="mb-3">
                    <Form.Label className='fs-np-14'>Apply As *</Form.Label>
                    <div className='d-flex'>
                        <Form.Check
                            type="radio"
                            label="Local Student"
                            name="formHorizontalRadios"
                            id="formHorizontalRadios1"
                            className='fs-np-14 pe-3'
                            onChange={(e) => setLocation(1)} value={location}
                            defaultChecked

                        />
                        <Form.Check
                            type="radio"
                            label="International Student"
                            name="formHorizontalRadios"
                            id="formHorizontalRadios2"
                            className='fs-np-14'
                            onChange={(e) => setLocation(2)} value={location}

                        />
                    </div>
                </Form.Group>
                <Form.Group className="mb-3">
                    {/* select with option */}
                    <Form.Control
                        as="select"
                        className='rounded-0 form-select form-select-sm'
                        onChange={(e) => changeCourseType(e)} value={courseType} id="coursetype-select" disabled={courseTypeSelectState}
                    >
                        <option value="">Select Course Type</option>
                        {CourseTypes?.map((type, index) => (
                            <>
                            {type.id!==3 &&
                            <option key={index} value={type.id}>{type.title}</option>
                            }
                            
                            </>
                        ))}
                    </Form.Control>
                    
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Control
                        as="select"
                        className='rounded-0 form-select form-select-sm'
                        onChange={(e) => setCourse(e.target.value)} value={course} id="course-select" disabled={courseSelectState}
                    >
                        <option value="">Select Course</option>
                        <option value="000000000000000000000000">Need advise with program choice</option>
                        {courses?.map((course, index) => (
                            <option key={index} value={course._id}>{course.course_name}</option>
                        ))}
                    </Form.Control>
                    
                </Form.Group>
                { location==1 ? (
                <Form.Group className="mb-3">
                    <Form.Label className='fs-np-14'>Are you looking for financial aid help?</Form.Label>
                    <div className='d-flex'>
                        <Form.Check
                            type="radio"
                            label="No"
                            name="financialaid"
                            id="financialaid1"
                            className='fs-np-14 pe-3'
                            onChange={(e) => setFinancialSelect(false)} value={financialSelect}
                            defaultChecked
                        />
                        <Form.Check
                            type="radio"
                            label="Yes"
                            name="financialaid"
                            id="financialaid2"
                            className='fs-np-14'
                            onChange={(e) => setFinancialSelect(true)} value={financialSelect}
                        />
                    </div>

                    {/* <Form.Control
                        as="select"
                        className='rounded-0 form-select form-select-sm'
                        onChange={(e) => setCourse(e.target.value)} value={course}
                    >
                        <option value="">Select</option> */}
                        {/* <option value="000000000000000000000000">Need advise with program choice</option> */}
                        {/* {courses?.map((course, index) => (
                            <option key={index} value={course._id}>{course.course_name}</option>
                        ))} */}
                        {/* <option value="1">Option 1</option>
                        <option value="2">Option 2</option>
                    </Form.Control> */}
                    
                </Form.Group>
                ) : (
                    ""
                )}


                {/* <input type="hidden" name="course_id" value={source} /> */}
                
            </Form>
            </Modal.Body>
            <Modal.Footer>
                <div className='mx-auto'>
                    <Button className='btn btn-secondary rounded-0' variant="default" onClick={handleClose}>
                        Close
                    </Button>
                     &nbsp;
                    <Button className='btn btn-np-success rounded-0' variant="default" onClick={handleSubmit} id="btn-submit">
                        Submit
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
        </>
  )
}

export default Router